<template>
  <div>
    <van-nav-bar class="nav_bar_view" left-arrow>
      <template #left>
        <img
          @click="returnPage"
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          alt=""
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">{{ title }}</span>
      </template>
    </van-nav-bar>
    <van-field
      label-class="field_view"
      v-model.trim="phone"
      name="用户名"
      label="+63"
      placeholder="请输入手机号"
      label-width="48px"
    >
      <div
        style="display: flex; flex-direction: row"
        slot="label"
        @click="toCodeSelection(4)"
      >
        <span>{{ label }}</span>
        <van-icon name="arrow-down" style="margin-top: 6px; margin-left: 3px" />
      </div>
    </van-field>
    <van-field
      center
      clearable
      label="验证码"
      placeholder="请输入验证码"
      label-width="48px"
      v-model.trim="captchaCode"
    >
      <template #button>
        <van-button
          class="get_code_btn"
          size="small"
          type="primary"
          id="btn_code"
          @click="captchaLogin(this)"
          >获取验证码</van-button
        >
      </template>
    </van-field>
    <div class="popup_bottom_view">
      <van-button round block type="info" color="#01B15B" @click="bandPhone"
        >确定</van-button
      >
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { setToken,getToken,removeToken } from "@/utils/auth.js";

import {
  pwdLogin,
  updatePassword,
  captcha_sendSms,
  login_bandPhone,
  update,
} from "@/service/api/index.js";
export default {
  data() {
    return {
      title: "",
      option: this.$route.query.option,
      phone: "",
      captchaCode: "",
      time: true,
      captchaId: "",
      openId:""
    };
  },
  computed: {
    label() {
      return this.$route.query.code || "+63";
    },
    countryCode() {
      return this.$route.query.countryCode || "PH";
    },
  },
  created() {
    if (this.option === "1") {
      this.title = "密码设置";
    } else {
      this.title = "绑定手机号";
    }
    if(getToken('openId')){
      this.openId = getToken('openId');
    }
  },
  methods: {
    returnPage() {
      this.$router.go(-1);
    },
    captchaLogin(e) {
      if (this.time) {
        captcha_sendSms({
          phone: this.phone,
          areaCode: this.label.replace("+", ""),
          countryCode: this.countryCode,
        }).then((res) => {
          if (res.status == 200) {
            this.time = false;
            this.captchaId = res.data.id;
            var btn = document.querySelector(".get_code_btn");
            var sum = 60;
            var timer;
            timer = setInterval(() => {
              sum--;
              btn.innerHTML = sum + "s";
              if (sum === 0) {
                btn.innerHTML = "获取验证码";
                this.time = true;
                clearInterval(timer);
                sum = 60;
              }
            }, 1000);
          }
        });
      }
    },
    toCodeSelection(option) {
      this.$router.replace({
        path: "/areaCodeSelection",
        query: { option: option, class: this.option, form: "/changePwd" },
      });
    },
    // update() {

    // },
    bandPhone() {
      if (this.option == 1) {
        updatePassword({
          loginName: this.phone,
          captchaCode: this.captchaCode,
          captchaId: this.captchaId,
        }).then((res) => {
          this.$router.push("/changeNewPassword");
        });
      } else {
        updatePassword({
          loginName: this.phone,
          captchaCode: this.captchaCode,
          captchaId: this.captchaId,
        }).then((res) => {
          if (res.status == 200) {
            update({ phone: this.phone,openId:this.openId }).then((res) => {
              if (res.status == 200) {
                if(res.message == 'openIdBind'){
                  Toast("绑定账号成功，请重新登录！");
                  setTimeout(()=>{
                    this.$router.push("/login");
                    removeToken("token");
                  },2000)
                }else{
                  Toast("修改成功！");
                  this.$router.replace("/user");
                }
              } else {
                return Toast(res.message);
              }
            });
          }
        });

        // let data = {
        //   loginName: this.phone,
        //   captchaId: this.captchaId,
        //   captchaCode: this.captchaCode,
        //   areaCode: this.label.replace("+", ""),
        //   countryCode: this.countryCode,
        // };
        // let data = {
        //   loginName: this.phone,
        //   captchaId: this.captchaId,
        //   captchaCode: this.captchaCode,
        //   areaCode: this.label.replace("+", ""),
        //   countryCode: this.countryCode,
        // };
        // login_bandPhone(data).then((res) => {
        //   if (res.status == 200) {
        //     setToken(res.data, "token");
        //     this.$router.push("/home");
        //   } else {
        //     return Toast(res.message);
        //   }
        // });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.get_code_btn {
  background: white;
  color: #00b05a;
  border: 0px solid white;
}
.popup_bottom_view {
  display: flex;
  align-items: center;
  height: 57px;
  padding: 0 18px;
  background: white;
}
</style>
